import { CATEGORIES, isBuyAndSellCategory } from '@kijiji/category'
import {
  GetPendingSearchInputDocument,
  GetUserLocationDocument,
} from '@kijiji/generated/graphql-types'
import { useDecision } from '@optimizely/react-sdk'
import { type GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import qs from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { ClientRender } from '@/components/shared/client-render'
import { ErrorBoundary } from '@/components/shared/error-boundary'
import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { PageContainer } from '@/components/shared/page-container'
import { RadiusBoostSystemMessage } from '@/components/shared/radius-boost-system-message/RadiusBoostSystemMessage'
import { SrpGrid, SrpHeader, SrpResultsSort } from '@/components/srp'
import { FiltersSidebar } from '@/components/srp/filters/FiltersSidebar'
import { TicketListingCard } from '@/components/srp/listing-card/ticket-listing-card/TicketListingCard'
import { SearchFloatingButtons } from '@/components/srp/search-floating-buttons'
import { SearchList } from '@/components/srp/search-list'
import { SrpBreadcrumb } from '@/components/srp/srp-breadcrumb/SrpBreadcrumb'
import { SrpError } from '@/components/srp/srp-error'
import { TopLeaderboardAdSlot } from '@/components-page/srp/advertisement/TopLeaderboardAdSlot'
import { SearchAdsWrapper } from '@/components-page/vip/advertisement/SearchAdsWrapper'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { PAGE_TYPE } from '@/constants/pageTypes'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { debugServerTime } from '@/domain/debugServerTiming'
import { initializePageServer } from '@/domain/initializePageServer'
import { getUserLocationFromSearchQuery } from '@/domain/location/getUserLocationFromSearchQuery'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { getSrpComposedUrl } from '@/domain/srp/getSrpComposedUrl'
import { getSrpContentfulData } from '@/domain/srp/getSrpContentfulData'
import {
  type GetSrpPropsWithCache,
  type PageRedirect,
  getSrpLoadData,
} from '@/domain/srp/getSrpLoadData'
import { redirectLocationlessUrl } from '@/domain/srp/page-redirects/redirectLocationlessUrl'
import { AdSense } from '@/features/advertisement/components/adsense'
import ClarivoyScript from '@/features/advertisement/components/clarivoy/clarivoyScript'
import useClarivoy from '@/features/advertisement/components/clarivoy/useClarivoy'
import { MapProvider } from '@/features/map/components/MapProvider'
import { useMapSRP } from '@/features/map/hooks/useMapSRP'
import { getMenuPrefetch } from '@/features/navigation/api/getMenuPrefetch'
import { ProductCarouselSeoJsonLd } from '@/features/seo/components/ProductCarouselSeoJsonLd'
import { SeoMetadata } from '@/features/seo/components/SeoMetadata'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSearchLoadingState } from '@/hooks/srp/useSearchLoadingState'
import { useChameleon } from '@/hooks/useChameleon'
import { useExtendedSearch } from '@/hooks/useExtendedSearch'
import { useFavouriteListingUpdate } from '@/hooks/useFavouriteListingUpdate'
import { useLocale } from '@/hooks/useLocale'
import { useSaveSearchFromLogin } from '@/hooks/useSaveSearchFromLogin'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { useSrpTracking } from '@/lib/ga/hooks/useSrpTracking'
import profile from '@/lib/metrics/profileWrapper'
import { FEATURE_FLAG } from '@/lib/optimizely'
import OptimizelyServerManager from '@/lib/optimizely/OptimizelyServerManager'
import { type BasePageProps } from '@/pages/_app'
import { Flex } from '@/ui/atoms/flex'
import { getUserDevice } from '@/utils/getUserDevice'
import { sendToLogger } from '@/utils/sendToLogger'

const AppPenRealEstateModal = dynamic(
  () => import('@/components/srp/AppPenRealEstateModal').then((mod) => mod.AppPenRealEstateModal),
  { ssr: false }
)

const BaseLeaderboardAdSlot = dynamic(
  () =>
    import('@/components-page/srp/advertisement/BaseLeaderboardAdSlot').then(
      (mod) => mod.BaseLeaderboardAdSlot
    ),
  { ssr: false }
)

const ContentfulContent = dynamic(
  () =>
    import('@/components/shared/contentful-content/ContentfulContent').then(
      (mod) => mod.ContentfulContent
    ),
  { ssr: false }
)

const MapSRP = dynamic(() => import('@/features/map').then((mod) => mod.MapSRP), {
  ssr: false,
})

export type SRPErrors = {
  hasServerError?: boolean
  hasResultsError?: boolean
}

export interface SearchPageServerProps extends BasePageProps {
  /**
   * Error states from SRP Anvil calls
   */
  errors?: SRPErrors
  /**
   * An ID that is used to identify the page load. This allows us to detect new
   * page loads where the url hasn't changed.
   */
  pageLoadId: string
}

const SearchPage = ({ pageLoadId, userAgent, errors: serverErrors }: SearchPageServerProps) => {
  const { setLoadingStates } = useSearchLoadingState()
  const [hasClientFetchAttemptError, setHasClientFetchAttemptError] = useState(false)

  const { asPath, query, replace } = useRouter()
  const { routeLocale } = useLocale()

  const { data: userData } = useSession()
  const { updateFavourite } = useFavouriteListingUpdate()

  const srpUrl = getSrpComposedUrl(query)

  const isMobileForAds = getUserDevice(userAgent).isPhone

  const {
    data: srpData,
    loading: srpLoading,
    called: srpCalled,
    client,
    loadingFilters,
  } = useGetSearchResultsData({
    fetchPolicy: 'cache-first',
    onError: () => {
      setHasClientFetchAttemptError(true)
      setLoadingStates(false)

      // errored from network request, not the cache
      client.writeQuery({
        query: GetPendingSearchInputDocument,
        data: { srp: { pendingSearchInput: null } },
      })
    },
    skip: hasClientFetchAttemptError,
  })

  const isInitialLoad = !srpData && srpLoading

  const { searchQuery, results, pagination } = srpData || {}

  const category = getSearchCategoryFromSearchQuery(searchQuery?.category)

  const currentPage = pagination ? getCurrentPageFromPagination(pagination) : 1
  const totalPageCount = pagination ? Math.ceil(pagination.totalCount / pagination.limit) : 1
  const listings = results?.mainListings || []

  const textJsonPromise = useMemo(() => {
    return getSrpContentfulData({ languageKey: routeLocale, url: srpUrl })
  }, [routeLocale, srpUrl])

  const errors = {
    hasServerError: !!serverErrors?.hasServerError,
    hasResultsError: srpCalled && !srpLoading && srpData?.results === null,
  }

  // Memoize the transformed location to prevent unnecessary re-renders for chameleon
  const memoizedLocation = useMemo(() => {
    return getUserLocationFromSearchQuery(searchQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery?.location])

  useChameleon({
    categoryId: category.id,
    location: memoizedLocation,
    userAgent,
  })

  /**
   * UseEffect to be triggered if the user has signed in to favourite a listing
   * The decision is made based on a query parameter
   * Once the function has been triggered the query parameter should be removed from the URL
   */
  useEffect(() => {
    const listingId = query.listingId
    if (typeof listingId !== 'string' || !userData?.user) return
    /**
     * Remove listingId from the query params
     * If the initial value of listingId is not overwritten, the request will happen multiple times
     */
    delete query.listingId
    const { url } = qs.parseUrl(asPath)
    replace(qs.stringifyUrl({ url, query }))

    updateFavourite(false, {
      variables: { listingId },
      onCompleted: () => {
        trackEvent({
          action: GA_EVENT.WatchlistAdd,
          label: `adId=${listingId}`,
        })
      },
      onError: (error) => {
        // catch this error legitimate error
        if (error.message.includes('already favourited')) {
          return
        }
      },
    })
  }, [userData, asPath, updateFavourite, query, replace])

  useSaveSearchFromLogin({ searchString: srpData?.searchQuery.searchString })
  useSrpTracking()

  const { spacing } = useTheme()

  const { extendedListings } = useExtendedSearch()

  const { isScriptLoadable: isClarivoyScriptLoadable } = useClarivoy({
    listings,
    category,
    pageType: PAGE_TYPE.SEARCH_RESULTS_PAGE,
  })

  const [mastheadRemovalDecision] = useDecision(FEATURE_FLAG.MASTHEAD_REMOVAL_EXP)
  const isMastheadRemoved =
    mastheadRemovalDecision?.enabled === true && mastheadRemovalDecision?.variationKey === 'b'

  const [adsenseRemovalDecision] = useDecision(FEATURE_FLAG.ADSENSE_REMOVAL_EXP)
  const isAdsenseRemoved =
    adsenseRemovalDecision?.enabled === true && adsenseRemovalDecision?.variationKey === 'b'
  const router = useRouter()
  const isMapSRPEnabledFlag = useMapSRP(category.id)
  const [shouldRenderMapSRP, setShouldRenderMapSRP] = useState(
    isMapSRPEnabledFlag &&
      !!router.query.bbox &&
      !!router.query.geoCluster &&
      !!router.query.clusterExpansion
  )
  const renderSearchListings = () => {
    if (errors.hasResultsError) {
      return <SrpError />
    }

    if (category.id === CATEGORIES.TICKETS_CATEGORY_ID) {
      return <TicketListingCard />
    }

    return (
      <SearchList
        extendedRadiusListings={extendedListings}
        totalPageCount={totalPageCount}
        isMobile={isMobileForAds}
      />
    )
  }

  const hasRefetchUrlError = false

  return (
    <>
      {isClarivoyScriptLoadable && <ClarivoyScript defer />}

      {isBuyAndSellCategory(category.id) && (
        <ProductCarouselSeoJsonLd id="product-carousel-srp" listings={listings} />
      )}

      <SeoMetadata pageLoadId={pageLoadId}>
        {(pageH1) => (
          <SearchAdsWrapper>
            <BaseLayout
              showSearchBar={true}
              withAuthModal={true}
              includeRUMScripts={true}
              rumPageLabel="SRP"
            >
              <ErrorBoundary fingerprintId="SRP">
                {/* If there is server-side search or client-side refetch url error */}
                {hasClientFetchAttemptError || hasRefetchUrlError ? (
                  <SrpError />
                ) : (
                  <PageContainer
                    bottom={spacing.default}
                    top={spacing.default}
                    isFullMax={shouldRenderMapSRP}
                  >
                    <SrpBreadcrumb />

                    {isMastheadRemoved ? null : (
                      <TopLeaderboardAdSlot isMobileForAds={isMobileForAds} />
                    )}

                    <SrpHeader
                      h1={pageH1}
                      currentPage={currentPage}
                      loading={isInitialLoad}
                      isMobile={isMobileForAds}
                      shouldRenderMapSRP={shouldRenderMapSRP}
                      setShouldRenderMapSRP={setShouldRenderMapSRP}
                    />

                    {/* TODO: Temporary flag test for MAP_SRP feature; cleanup markup with KJCA-3090 story. */}
                    {/* TODO: Check for isMapSRPEnabled and toggle is set to MapView with KJCA-3302 */}
                    {shouldRenderMapSRP ? (
                      <ClientRender>
                        <MapProvider>
                          <MapSRP renderSearchListings={renderSearchListings} />
                        </MapProvider>
                      </ClientRender>
                    ) : (
                      <SrpGrid>
                        <FiltersSidebar
                          isMobileForAds={isMobileForAds}
                          isLoadingFilters={loadingFilters}
                        />

                        <Flex flexDirection="column" gap={spacing.default}>
                          <SrpResultsSort />

                          <RadiusBoostSystemMessage />

                          {!isAdsenseRemoved && <AdSense id="AFSTop" />}

                          {renderSearchListings()}

                          <BaseLeaderboardAdSlot />
                        </Flex>
                      </SrpGrid>
                    )}

                    {textJsonPromise && (
                      <ErrorBoundary fingerprintId="ContentfulContent">
                        <ContentfulContent userAgent={userAgent} textJson={textJsonPromise} />
                      </ErrorBoundary>
                    )}

                    <AppPenRealEstateModal categoryId={category.id} />

                    <SearchFloatingButtons isMobileForAds={isMobileForAds} />
                  </PageContainer>
                )}
              </ErrorBoundary>
            </BaseLayout>
          </SearchAdsWrapper>
        )}
      </SeoMetadata>
    </>
  )
}

const isRedirectUrl = (redirect: GetSrpPropsWithCache): redirect is PageRedirect =>
  !!(redirect as PageRedirect).redirect

export const getServerSideProps: GetServerSideProps<SearchPageServerProps> = profile<
  GetServerSideProps<SearchPageServerProps>
>('srp', 'getServerSideProps', async (context) => {
  const gSSPstart = Date.now()
  const { query, req, res } = context

  const {
    apolloClient,
    forwardedHeaders,
    basePageProps,
    locale: { cookieLocale },
  } = await initializePageServer('srp', context, [
    TRANSLATION_KEYS.SRP,
    TRANSLATION_KEYS.LISTING,
    TRANSLATION_KEYS.FEEDBACK,
    TRANSLATION_KEYS.FAVOURITES,
  ])

  const url = getSrpComposedUrl(query)

  /**
   * Queries we want to pre-fetch on the server to load asap in the page (use for the NAVIGATION_SRP experiment)
   * */
  const location = apolloClient.readQuery({ query: GetUserLocationDocument })
  const locationId = location?.userLocation.id

  /**
   * When the URL doesn't contain the location information
   * then use the user preferences to execute the SRP/BRP search
   * AND url gets updated to match the location search
   */
  const locationlessRedirect = await profile(
    'srp',
    'redirectLocationlessUrl',
    redirectLocationlessUrl
  )({ url, cookies: req.cookies })

  if (locationlessRedirect) {
    return { redirect: locationlessRedirect.redirect }
  }

  // Fetch the Optimizely decision for the NAVIGATION_SRP experiment
  try {
    const optimizelyManager = OptimizelyServerManager.getInstance()

    if (basePageProps.machId) {
      const decision = await optimizelyManager.decide(
        FEATURE_FLAG.NAVIGATION_SRP,
        basePageProps.machId
      )

      if (decision.enabled) {
        await profile(
          'srp',
          'getMenuPrefetch',
          getMenuPrefetch
        )({ apolloClient, cookieLocale, locationId })
      }
    }
  } catch (err) {
    sendToLogger(err, { fingerprint: ['getMenuPrefetchSrp'] })
  }

  const srpPropsOrRedirect = await profile(
    'srp',
    'getSrpLoadData',
    getSrpLoadData
  )({
    apolloClient,
    basePageProps,
    requestHeaders: { headers: forwardedHeaders, cookies: req.cookies },
    url,
  })

  if (isRedirectUrl(srpPropsOrRedirect)) {
    return { redirect: srpPropsOrRedirect.redirect }
  }

  debugServerTime('props', res, gSSPstart)

  return { props: srpPropsOrRedirect }
})

export default SearchPage
