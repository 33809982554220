import { differenceInDays, differenceInMilliseconds } from 'date-fns'

import { type RouteLocale } from '@/hooks/useLocale'
import {
  DAY_IN_MS,
  HOUR_IN_MS,
  hoursFromMilliseconds,
  MINUTE_IN_MS,
  minutesFromMilliseconds,
  WEEK_IN_MS,
  YEAR_IN_MS,
} from '@/utils/time'

type ListingSortingDateLabel = {
  key: string
  label: string
  params?: { [x: string]: string | number }
}

/**
 * It returns key and parameters to each type of time of activation label should be shown for each listing.
 * It will show a formatted date if the listing was posted more than a day ago.
 * The response of this function should be used with the "srp" translation function (i.e., t(key, {...params}))
 *
 * @param sortingDateTime
 * @param routeLocale
 * @param isMobileFormat
 *
 *  * @example
 * Example 1: Listing activated less than a minute ago
 * const sortingDateTime1 = new Date();
 * const routeLocale1 = 'en-US';
 * const result1 = getListingActivationLabel(sortingDateTime1, routeLocale1);
 * console.log(result1); // { key: 'listing.sorting_time.minutes', params: { minutes: '1', count: 1 } }
 *
 * Example 2: Listing activated less than a minute ago with short format
 * const sortingDateTime1 = new Date();
 * const routeLocale1 = 'en-US';
 * const result1 = getListingActivationLabel(sortingDateTime1, routeLocale1);
 * console.log(result1); // { key: 'listing.sorting_time.minutes_short', params: { minutes: '1', count: 1 } }
 *
 * Example 3: Listing activated 2 hours ago
 * const sortingDateTime2 = new Date('2023-09-01T10:00:00');
 * const routeLocale2 = 'fr-FR';
 * const result2 = getListingActivationLabel(sortingDateTime2, routeLocale2);
 * console.log(result2); // { key: 'listing.activation_time.hours_ago', params: { hours: '2', count: 2 } }
 *
 * Example 4: Listing activated 3 days ago
 * const sortingDateTime3 = new Date('2023-08-29T12:00:00');
 * const routeLocale3 = 'en-US';
 * const result3 = getListingActivationLabel(sortingDateTime3, routeLocale3);
 * console.log(result3); // { key: 'listing.activation_time.days', params: { days: '3', count: 3 } }
 **/
export const getListingSortingDateLabel = (
  sortingDateTime: Date,
  routeLocale: RouteLocale,
  isMobileFormat?: boolean
): ListingSortingDateLabel | null => {
  const currentTime = new Date()

  const timeDifferenceMS = differenceInMilliseconds(currentTime, sortingDateTime)

  // Listing was posted Less than a minute ago
  if (timeDifferenceMS <= MINUTE_IN_MS) {
    return {
      key: isMobileFormat ? 'listing.sorting_time.minutes_short' : 'listing.sorting_time.minutes',
      label: 'listing.sorting_time.minutes_label',
      params: { minutes: '1', count: 1 },
    }
  }

  // Listing was posted less than an hour ago
  if (timeDifferenceMS < HOUR_IN_MS) {
    const minutes = minutesFromMilliseconds(timeDifferenceMS)
    return {
      key: isMobileFormat ? 'listing.sorting_time.minutes_short' : 'listing.sorting_time.minutes',
      label: 'listing.sorting_time.minutes_label',
      params: { minutes: `${minutes}`, count: minutes },
    }
  }

  // Listing was posted less than a day ago
  if (timeDifferenceMS < DAY_IN_MS) {
    const hours = hoursFromMilliseconds(timeDifferenceMS)
    return {
      key: isMobileFormat
        ? 'listing.activation_time.hours_ago_short'
        : 'listing.activation_time.hours_ago',
      label: 'listing.activation_time.hours_ago_label',
      params: { hours: `${hours}`, count: hours },
    }
  }

  // Listing was posted less than a week ago
  if (timeDifferenceMS < WEEK_IN_MS) {
    const days = differenceInDays(currentTime, sortingDateTime)
    return {
      key: isMobileFormat ? 'listing.activation_time.days_short' : 'listing.activation_time.days',
      label: 'listing.activation_time.days_label',
      params: { days: `${days}`, count: days },
    }
  }

  // Listing was posted less than a month ago
  // Case 1 - where 4-6 weeks are considered as 1 month
  // Case 2 - and 7-10 weeks are considered as 2 months
  const weeks = Math.floor(timeDifferenceMS / WEEK_IN_MS)
  if (weeks < 4) {
    return {
      key: isMobileFormat ? 'listing.activation_time.weeks_short' : 'listing.activation_time.weeks',
      label: 'listing.activation_time.weeks_label',
      params: { weeks: `${weeks}`, count: weeks },
    }
  } else if (weeks >= 4 && weeks <= 6) {
    return {
      key: isMobileFormat
        ? 'listing.activation_time.months_short'
        : 'listing.activation_time.months',
      label: 'listing.activation_time.months_label',
      params: { months: '1', count: 1 },
    }
  } else if (weeks >= 7 && timeDifferenceMS < YEAR_IN_MS) {
    return {
      key: isMobileFormat
        ? 'listing.activation_time.months_short'
        : 'listing.activation_time.months',
      label: 'listing.activation_time.months_label',
      params: { months: '2', count: 2 },
    }
  }

  // cases for more than 2 months ago with formatted date
  const years = timeDifferenceMS / YEAR_IN_MS
  if (years >= 1) {
    const yearsAgo = Math.floor(years)
    return {
      key: isMobileFormat ? 'listing.activation_time.years_short' : 'listing.activation_time.years',
      label: 'listing.activation_time.years_label',
      params: { years: yearsAgo.toString(), count: yearsAgo },
    }
  }

  // case default
  return null
}
