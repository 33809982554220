import {
  CATEGORIES,
  isAllCategoriesExceptVerticals,
  isAnyJobsCategory,
  isAnyServicesCategory,
} from '@kijiji/category'
import { type AutosSurcharges, type ListingPriceType } from '@kijiji/generated/graphql-types'
import ShippingIcon from '@kijiji/icons/src/icons/Shipping'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger, ShowWhenSmaller } from '@/components/shared/breakpoint'
import { ImageYAMS } from '@/components/shared/image/ImageYAMS'
import { FavouriteButton } from '@/components/shared/listing/FavouriteButton'
import { AttributeList } from '@/components/srp/listing-card/attribute-list'
import { AutosBadges } from '@/components/srp/listing-card/autos-badges'
import { ListingDetails } from '@/components/srp/listing-card/listing-details'
import { ListingImageBadge } from '@/components/srp/listing-card/listing-image-badge'
import { ListingPrice } from '@/components/srp/listing-card/listing-price'
import { AdditionalOptions } from '@/components/srp/listing-card/listing-updates'
import { RealEstateAttributesList } from '@/components/srp/listing-card/real-estate-attributes/RealEstateAttributes'
import {
  ListingBadge,
  ListingCardBody,
  ListingCardContainer,
  ListingCardDescription,
  ListingCardDetails,
  ListingCardHeading,
  ListingCardLink,
  ListingCardLogoContainer,
  ListingCardTopData,
  ListingCardWithDescription,
  ListingImageBase,
  ListingRightSide,
  ShippingBadge,
} from '@/components/srp/listing-card/styled'
import { type RealEstateAttributes } from '@/components/srp/search-list/getRealEstateListingAttributes'
import { highlightKeywords } from '@/components/srp/search-list/highlightKeywords'
import { Showcase } from '@/components/srp/showcase'
import { truncateText } from '@/domain/srp/truncateText'
import { type ListingBadgeLabel } from '@/types/listings'
import { type Listing } from '@/types/search'
import { Flex } from '@/ui/atoms/flex'

/** Possible icons for attribute badges in listing */
type BadgeIconType = 'shippedBySeller'
export const ListingBadgeIcons: { [x in BadgeIconType]: ReactNode } = {
  shippedBySeller: <ShippingIcon size="xsm" aria-hidden />,
}

type AutosListingAttributes = {
  /**
   * List of dealer updates
   */
  dealerUpdates?: string[]
  /**
   * Defines if car listing has Carfax report
   */
  hasCarfaxReport?: boolean
  /**
   * Defines if car listing is certified pre-owned
   */
  isCertifiedPreOwned?: boolean
  /**
   * Retail price of listing
   * It will only have a value if the car is new
   */
  msrp?: number
  /**
   * Defines if listing has financing option
   * determined by finacingAvailable flag
   */
  hasFinancingOption?: boolean
}

export type ListingCardPrice = {
  /**
   * Current listing price
   */
  amount?: number
  /**
   * Original listing price, if it has been reduced
   */
  original?: number
  /**
   * Type of listing price
   */
  type?: ListingPriceType
  /**
   * Taxes or luxury surcharge applied to the listing
   */
  surcharges?: AutosSurcharges
}

type RealEstateListingAttributes = {
  /**
   * Flag to define if listing has virtual tour
   */
  virtualTour?: boolean
  /**
   * Listing for rental badge
   */
  rentalBadge?: string
}

type ListingActions = {
  /**
   * Action to add Listing click to dataLayer
   */
  handleTrackListingClick: () => void
}

export type ListingCardProps = ListingActions & {
  /**
   * Activation date for the listing
   */
  activationDate?: string | null
  /**
   * List of additional options
   */
  additionalOptions?: string[]
  /**
   * Type of ad (i.e. organic, topAds, CAS)
   */
  adSource: string
  /**
   * Attributes specific for autos categories
   */
  autos: AutosListingAttributes
  /**
   * Category Id of the current listing
   */
  categoryId: number
  /**
   * Listing description
   */
  description?: Listing['description']
  /**
   * Listing Id
   */
  id: string
  /**
   * Defines if the listing has a badge
   */
  imageBadge?: ListingBadgeLabel
  /**
   * Listing position
   */
  index: number
  /**
   * List of attributes to be displayed inline below the description
   */
  inlineAttributes?: string[]
  /**
   * Defines if this is a highlighted listing
   */
  isHighlight?: boolean
  /**
   * Listing location name
   */
  locationName: string
  /**
   * Company logo src
   */
  logoSrc?: string
  /**
   * Main image src
   */
  mainImage?: string | null
  /**
   * Listing price
   */
  price: ListingCardPrice
  /**
   * Distance from the search location, in metres.
   */
  proximity?: string
  /**
   * Date of posting of the listing
   */
  postDate?: string
  /**
   * Real estate specific attributes
   */
  realEstate: RealEstateListingAttributes
  /**
   * Defines if this listing will be shipped by seller
   */
  isShippedBySeller?: boolean
  /**
   * Rental unit attributes to be displayed on listing card
   */
  realEstateAttributes: RealEstateAttributes
  /**
   * Sorting date of the listing
   */
  sortingDate?: string | null
  /**
   * Additional pictures src used for the showcase
   */
  showcaseImages?: string[]
  /**
   * Listing title
   */
  title: string
  /**
   * Listing seo link
   */
  url: string
  /**
   * is top ad
   */
  promotionLabel: string
  /**
   * Defines if listing is in the Top Ads section
   */
  isInTopAdsSection?: boolean
  /**
   * Defines if user agent is from a mobile device
   */
  isMobile?: boolean
  /**
   * Global search keyword, used for highlighting keyword on listing card
   */
  globalKeyword?: string
  /**
   * Global category id
   */
  globalCategoryId?: number
}

export const ListingCard: FC<ListingCardProps> = ({
  activationDate,
  adSource,
  additionalOptions = [],
  autos,
  categoryId,
  description: possiblyNullDescription = '',
  handleTrackListingClick,
  id,
  imageBadge,
  inlineAttributes,
  isHighlight,
  isShippedBySeller,
  locationName,
  logoSrc,
  mainImage,
  price,
  proximity,
  realEstate,
  realEstateAttributes,
  sortingDate,
  showcaseImages,
  title,
  url,
  isInTopAdsSection,
  isMobile,
  index,
  globalKeyword,
  globalCategoryId,
}) => {
  const { t } = useTranslation('srp')
  const { colors, spacing } = useTheme()
  const description = possiblyNullDescription || ''

  /** We want to hide listing price for any services or jobs category */
  const shouldHideListingPrice = isAnyServicesCategory(categoryId) || isAnyJobsCategory(categoryId)

  /** Testing use of AVIF only in Buy & Sell category */
  const yamsImageExtension =
    globalCategoryId === CATEGORIES.BUY_AND_SELL_CATEGORY_ID ? 'AVIF' : 'WEBP'

  const { rentalBadge, virtualTour } = realEstate
  const {
    dealerUpdates = [],
    hasCarfaxReport,
    isCertifiedPreOwned,
    msrp,
    hasFinancingOption,
  } = autos

  /**
   * Elements will show up in the listing in different placements depending on the screen size
   * render functions avoids duplication of components definition
   *  */
  const renderInlineAttributes = (testId: string) => {
    const shouldShowInlineAttributes = !!inlineAttributes && inlineAttributes.length > 0
    return (
      <>
        {shouldShowInlineAttributes && (
          <AttributeList attributes={inlineAttributes} listingId={id} testId={testId} />
        )}
      </>
    )
  }
  /**
   * Adds real estate attributes (icons + value) to the listing card
   * Only for the long term rentals (LTRs) category (categoryId = 37)
   * @param {string} testId - data-testId for the list of attributes
   * @returns {ReactNode | null} - list of real estate attributes or null if not LTRs category
   */
  const renderRealEstateAttributes = (testId: string) => {
    return categoryId === CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID ? (
      <RealEstateAttributesList
        attributes={realEstateAttributes}
        listingId={id}
        testId={testId}
      ></RealEstateAttributesList>
    ) : null
  }

  const renderRealEstateBadges = (testId: string) => {
    if (!rentalBadge && !virtualTour) return null

    return (
      <Flex data-testid={testId} gap={spacing.defaultSmall}>
        {virtualTour && (
          <ListingBadge bgColor={colors.grey.primary}>
            {t('listing.attributes.virtual_tour')}
          </ListingBadge>
        )}

        {rentalBadge && (
          <ListingBadge data-testid="listing-rental-badge" bgColor={colors.violet.primary}>
            {rentalBadge}
          </ListingBadge>
        )}
      </Flex>
    )
  }

  const renderLogo = (testId: string) => {
    if (!logoSrc) return null

    return (
      <ListingCardLogoContainer>
        <ImageYAMS data-testid={testId} src={logoSrc} fill alt="" />
      </ListingCardLogoContainer>
    )
  }

  const renderShippedBySellerBadge = (testId: string) => {
    if (!isShippedBySeller) return null

    return (
      <ShippingBadge as="div" data-testid={testId}>
        {ListingBadgeIcons.shippedBySeller}
        <span>{t(`listing.shipping`)}</span>
      </ShippingBadge>
    )
  }

  const renderAutosBadge = (testId: string) => {
    if (!hasCarfaxReport && !isCertifiedPreOwned && !hasFinancingOption) return null

    return (
      <AutosBadges testId={testId}>
        {hasCarfaxReport && <AutosBadges.CarFax />}
        {isCertifiedPreOwned && <AutosBadges.CertifiedPreOwned />}
        {hasFinancingOption && <AutosBadges.Financing />}
      </AutosBadges>
    )
  }

  const listingCardWidth = isHighlight ? 500 : 400

  const getListingCardContent = () => {
    const showListingCardWithDescription = description && isAllCategoriesExceptVerticals(categoryId)

    if (showListingCardWithDescription) {
      return (
        <ListingCardWithDescription
          color={colors.grey.light1}
          data-testid="listing-description"
          size="medium"
        >
          {highlightKeywords(id, description, globalKeyword)}
        </ListingCardWithDescription>
      )
    }
    return (
      <ListingCardDescription
        color={colors.grey.light1}
        data-testid="listing-description"
        size="medium"
      >
        {highlightKeywords(id, description, globalKeyword)}
      </ListingCardDescription>
    )
  }

  return (
    <ListingCardContainer
      data-testid="listing-card"
      data-listingid={id}
      {...(imageBadge ? { 'data-badge': imageBadge } : {})}
    >
      <ListingCardTopData isHighlight={isHighlight}>
        <ListingImageBase
          badge={imageBadge ? <ListingImageBadge imageBadge={imageBadge} /> : null}
          data-testid={`listing-card-image-container${isHighlight ? '-highlight' : ''}`}
          imageComponent={
            <ImageYAMS
              alt={truncateText(description, 100) || title}
              data-testid="listing-card-image"
              src={mainImage}
              width={listingCardWidth}
              height={listingCardWidth}
              priority={isInTopAdsSection && index === 0}
              extension={yamsImageExtension}
              loading={index < 2 ? 'eager' : 'lazy'}
            />
          }
          size={isHighlight ? 'large' : 'medium'}
        />
        <ListingCardDetails
          justifyContent="space-between"
          media={{
            medium: {
              gap: spacing.default,
            },
            large: {
              gap: '5.6rem',
            },
          }}
        >
          <ListingCardBody>
            {shouldHideListingPrice ? null : (
              <ListingPrice
                displayInlinePrice={isHighlight}
                msrp={msrp}
                priceAmount={price.amount}
                priceOriginal={price.original}
                type={price.type}
                surcharges={price.surcharges}
              />
            )}

            <ListingCardHeading
              color={colors.grey.primary}
              data-testid="listing-title"
              forwardedAs="h3"
              size="medium"
            >
              <ListingCardLink
                data-testid="listing-link"
                href={url}
                onClick={handleTrackListingClick}
              >
                {highlightKeywords(id, title, globalKeyword)}
              </ListingCardLink>
            </ListingCardHeading>

            <ListingDetails
              activationDate={activationDate}
              sortingDate={sortingDate}
              locationName={locationName}
              proximity={proximity}
              isShippedBySeller={isShippedBySeller}
              listingAdSource={adSource}
              isMobileFormat={isMobile}
            />

            {description && getListingCardContent()}

            <ShowAtOrLarger breakpoint="medium">
              <Flex gap={spacing.defaultSmall} flexDirection="column">
                {renderInlineAttributes('attribute-list-non-mobile')}
                {renderRealEstateAttributes('re-attribute-list-non-mobile')}
                {renderRealEstateBadges('listing-rental-badge-non-mobile')}
                {renderAutosBadge('listing-autos-badges-non-mobile')}
                {renderShippedBySellerBadge('shipping-badge')}
              </Flex>
            </ShowAtOrLarger>
          </ListingCardBody>

          <ListingRightSide>
            <FavouriteButton listingId={id} />
            <ShowAtOrLarger breakpoint="medium">
              {renderLogo('logo-container-non-mobile')}
            </ShowAtOrLarger>
          </ListingRightSide>
        </ListingCardDetails>
      </ListingCardTopData>

      <ShowWhenSmaller breakpoint="medium">
        <Flex flexDirection="column" gap={spacing.defaultSmall}>
          {renderAutosBadge('listing-autos-badges-mobile')}
          {renderInlineAttributes('attribute-list-mobile')}
          {renderRealEstateAttributes('re-attribute-list-mobile')}
          {renderRealEstateBadges('listing-rental-badge-mobile')}
          {renderLogo('logo-container-mobile')}
        </Flex>
      </ShowWhenSmaller>

      {(!!dealerUpdates.length || !!additionalOptions.length) && (
        <AdditionalOptions
          options={[...dealerUpdates, ...additionalOptions]}
          isDealerUpdates={!!dealerUpdates.length}
          listingId={id}
        />
      )}

      {!!showcaseImages?.length && (
        <Showcase
          seoUrl={url}
          showcaseImages={showcaseImages}
          handleListingCardClick={handleTrackListingClick}
          baseAltText={truncateText(description, 100)}
        />
      )}
    </ListingCardContainer>
  )
}
