import { APIProvider as GoogleAPIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps'
import { type ReactNode, useEffect, useState } from 'react'

import { type LanguageKey, getLocaleFromLang } from '@/domain/locale'
import { useLocale } from '@/hooks/useLocale'
import GoogleMaps from '@/lib/google/GoogleMaps'
import { sendToLogger } from '@/utils/sendToLogger'

type MapProviderProps = {
  children: ReactNode
  provider?: string
}

/**
 * Props for the Map component.
 *
 * @see https://visgl.github.io/react-google-maps/docs - Documentation for **vis.gl** (if integrating or using vis.gl for other mapping libraries).
 * @see https://developers.google.com/maps/documentation/javascript/reference/map - Documentation for **Google Maps** API.
 */
type MapProps = {
  provider?: string
} & React.ComponentProps<typeof GoogleMap>

/**
 * MapProvider component that manages the integration with different map providers.
 * Currently supports the 'google' provider, fetching the API key dynamically based on the language key.
 *
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The child components that will be rendered within the MapProvider.
 * @param {string} [props.provider='google'] - The map provider to use. Defaults to 'google'.
 *
 * @returns {React.ReactNode | null} - The component returns either the GoogleAPIProvider with the children or null if an unsupported provider is specified.
 *
 * @note This component fetches the Google Maps API key based on the current language and provider settings.
 */
const MapProvider: React.FC<MapProviderProps> = ({ children, provider = 'google' }) => {
  const { apiLocale } = useLocale()
  const { languageKey } = getLocaleFromLang(apiLocale)
  const [googleApiKey, setGoogleApiKey] = useState<string>('')

  const getGoogleMapsAPIKeyByInstance = async (languageKey: LanguageKey) => {
    try {
      const google = GoogleMaps.getInstance(languageKey)
      await google.getGoogleMapsAPIKey()
      setGoogleApiKey(google.apiKey)
    } catch (error) {
      setGoogleApiKey('')
      sendToLogger(error, { fingerprint: ['MapProviderAPIKey'] })
    }
  }

  useEffect(() => {
    const fetchGoogleApiKey = async () => {
      await getGoogleMapsAPIKeyByInstance(languageKey)
    }
    if (provider === 'google') fetchGoogleApiKey()
  }, [languageKey, provider])

  switch (provider) {
    case 'google':
      return <GoogleAPIProvider apiKey={googleApiKey}>{children}</GoogleAPIProvider>
    default:
      return null
  }
}

/**
 * MapListing component that renders a map from the specified provider.
 * Currently supports the 'google' provider and renders the GoogleMap component.
 *
 * @param {Object} props - The component's props.
 * @param {string} [props.provider='google'] - The map provider to use. Defaults to 'google'.
 * @param {Object} [props.rest] - Any other additional props passed to the GoogleMap component.
 *
 * @returns {React.ReactNode | null} - The component returns the GoogleMap component with the provided props or null if an unsupported provider is specified.
 *
 * @note This component is currently limited to rendering Google Maps based on the provider prop.
 */
const MapListing: React.FC<MapProps> = ({ provider = 'google', ...rest }) => {
  switch (provider) {
    case 'google':
      return <GoogleMap {...rest} />
    default:
      return null
  }
}

export { MapListing, MapProvider }
