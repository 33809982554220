import { AdvertisingSlot } from 'react-advertising'
import styled from 'styled-components'

import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const FiltersSidebarPanel = styled.div(({ theme }) => {
  return `
    background: ${theme.colors.white};
    border: .1rem solid ${theme.colors.grey.light4};
    display: none;
    padding: 0;

    ${MEDIA_QUERIES(theme).large}{
      display: block;
    }
  `
})

export const FiltersSidebarTitle = styled(HeadlineText)(
  ({ theme }) => `
  padding: 1.4rem ${theme.spacing.default};
`
)

export const SkyscraperAdSlot = styled(AdvertisingSlot)(
  ({ theme }) => `
  display: none;
  justify-content: center;
  margin-top:  ${theme.spacing.large};;
  overflow: hidden;
  position: sticky;
  top: 0;

  ${MEDIA_QUERIES(theme).large}{
    display: flex;
  }
`
)
