import { isAnyCarsVehiclesCategory, isAnyRealEstateCategory } from '@kijiji/category'
import { type AppliedAttributeFilter } from '@kijiji/generated/graphql-types'
import { organizationSeo } from '@kijiji/seo/configs/organizationSeo'
import { JSON_LD_ORGANIZATION_ID } from '@kijiji/seo/constants'
import { filterTypesInTitle } from '@kijiji/seo/constants/filterTypes'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useTranslation } from 'next-i18next'
import { NextSeo, ProductJsonLd } from 'next-seo'
import { type FC, type ReactNode, useMemo, useState } from 'react'

import { getListingCounts } from '@/components/srp/results-sort/results/getListingCounts'
import { DEFAULT_USER_LOCATION } from '@/constants/location'
import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { getSrpComposedUrl } from '@/domain/srp/getSrpComposedUrl'
import { getSrpMetaData } from '@/domain/srp/seo/getSrpMetaData'
import { generateSrpSeoTitle } from '@/features/seo/utils/generateSrpSeoTitle'
import { getListingsStructuredMarkup } from '@/features/seo/utils/getStructuredMarkup'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useLocale } from '@/hooks/useLocale'
import { type IntlResponse } from '@/types/translations'

export type SeoMetadataProps = {
  pageLoadId: string
  children: (searchH1: string | IntlResponse) => ReactNode
}

export const SeoMetadata: FC<SeoMetadataProps> = ({ children, pageLoadId }) => {
  const { query } = useRouter()

  const { data: { searchQuery, controls, pagination, results } = {} } = useGetSearchResultsData()
  const { topListings: topAds = [], mainListings: listings = [] } = results ?? {}
  const { apiLocale } = useLocale()
  const { t } = useTranslation('srp')

  const [organizationScript] = useState<ReactNode>(
    <Script
      key={`${pageLoadId}-${JSON_LD_ORGANIZATION_ID}`}
      id={JSON_LD_ORGANIZATION_ID}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSeo) }}
    />
  )

  const category = getSearchCategoryFromSearchQuery(searchQuery?.category)
  const location = searchQuery?.location || DEFAULT_USER_LOCATION
  const currentPage = pagination ? getCurrentPageFromPagination(pagination) : 1

  const { totalCount: totalListings = 0, limit = SRP_LISTING_LIMIT } = pagination ?? {}
  const resultsCounts = getListingCounts(totalListings, currentPage, limit)

  const { h1, title, ...seo } = getSrpMetaData({
    apiLocale,
    category: category,
    currentPage,
    filters: controls?.filtering || [],
    keyword: searchQuery?.keywords ?? '',
    location: location.name,
    totalListingsCount: pagination?.totalCount ?? 0,
    url: getSrpComposedUrl(query),
  })

  const appliedAttributeFilters =
    (searchQuery?.filters?.filter(
      (filter) => filter.__typename === 'AppliedAttributeFilter'
    ) as AppliedAttributeFilter[]) || []

  const titleSeo = generateSrpSeoTitle({
    filters: appliedAttributeFilters,
    filterTypesInTitle,
    locale: apiLocale,
    searchKeywords: searchQuery?.keywords,
    title,
    totalListings: resultsCounts.params.total,
    translation: t,
  })

  const isAutosOrRealEstateSearch = useMemo(
    () => isAnyCarsVehiclesCategory(category.id) || isAnyRealEstateCategory(category.id),
    [category.id]
  )
  const titleTemplate = searchQuery?.keywords ? '%s' : '%s | Free Local Classifieds - Kijiji'

  return (
    <div>
      {organizationScript}
      <NextSeo
        title={titleSeo}
        titleTemplate={titleTemplate}
        robotsProps={{ maxImagePreview: 'large' }}
        {...seo}
      />
      {isAutosOrRealEstateSearch && (
        <ProductJsonLd
          type="ItemList"
          productName=""
          itemListElement={getListingsStructuredMarkup(topAds.concat(listings), t)}
        />
      )}
      {children(h1)}
    </div>
  )
}
