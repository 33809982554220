import { PAGE_TYPE } from '@/constants/pageTypes'

export function determinePageNumber(pageNum: number, pageType?: PAGE_TYPE): string {
  if (pageType === PAGE_TYPE.VIEW_AD || pageType === PAGE_TYPE.ZERO_SEARCH_RESULTS_PAGE) {
    return '1'
  }

  if (pageNum === 1) {
    return '1'
  } else if (pageNum >= 2 && pageNum <= 5) {
    return '2'
  } else if (pageNum >= 6) {
    return '3'
  }

  return '1'
}
