import { type AppliedFilter } from '@kijiji/generated/graphql-types'

import { isAppliedAttributeFilter } from '@/types/search'

export const getAppliedAttributeFilters = (filters?: AppliedFilter[]) => {
  if (!filters) return ''

  return filters.reduce((acc: string, curr: AppliedFilter) => {
    if (isAppliedAttributeFilter(curr)) {
      // Manually format the filter name and its values
      const filterValues = curr.values.map((value) => `'${value}'`).join(',')
      const filterString = `${curr.filterName}:[${filterValues}]`
      return acc ? `${acc}; ${filterString}` : filterString
    }
    return acc
  }, '')
}
