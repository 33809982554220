/**
 * Takes the proximity value in meters
 * @returns Proximity label
 */
export const getListingProximityLabel = (distance?: number | null): string | undefined => {
  if (!distance) return

  /**
   * Transform value to Km
   */
  const distanceKm = distance / 1000

  /**
   * Round up the distance value if necessary / return proper label
   */
  return distanceKm % 1 === 0 ? `${distanceKm}km` : `< ${Math.ceil(distanceKm)}km`
}
