import { PAGE_TYPE } from '@/constants/pageTypes'

const pageTypeMapping: Record<string, string> = {
  [PAGE_TYPE.ZERO_SEARCH_RESULTS_PAGE]: 'zsp',
  [PAGE_TYPE.SEARCH_AD]: 'srl',
  [PAGE_TYPE.BROWSE_AD]: 'brl',
  [PAGE_TYPE.VIEW_AD]: 'vip',
}

/**
 * Converts an internal page type name to its corresponding Adsense tracking page type code.
 * If the internal page type name is not found in the mapping, an empty string is returned.
 *
 * @param {PAGE_TYPE} pageTypeName - The internal page type name.
 * @returns {string} The corresponding Adsense tracking page type code.
 *
 * @example
 *  determinePageType(PAGE_TYPE.SEARCH_AD) // Returns: 'srpl'
 *  determinePageType(PAGE_TYPE.VIEW_AD) // Returns: 'vip'
 *  determinePageType('SOME_UNKNOWN_PAGE_TYPE') // Returns: ''
 */
export function determinePageType(pageTypeName: string): string {
  return pageTypeMapping[pageTypeName] || 'o'
}
