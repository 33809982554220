import { PAGE_TYPE } from '@/constants/pageTypes'

/**
 * Returns the search type based on the presence of keywords.
 * @param {string} [keywords] - Optional keywords to check for.
 * @returns {PAGE_TYPE} The search type based on the presence of keywords.
 *
 * @example
 *  // Example usage:
 *  const searchType1 = getSearchTypeWithKeywords(); // returns PAGE_TYPE.BROWSE_AD
 *  const searchType2 = getSearchTypeWithKeywords("couch"); // returns PAGE_TYPE.SEARCH_AD
 */
export const getSearchTypeWithKeywords = (keywords?: string): PAGE_TYPE => {
  const hasKeyword = !!keywords

  if (hasKeyword) {
    return PAGE_TYPE.SEARCH_AD
  }

  return PAGE_TYPE.BROWSE_AD
}
