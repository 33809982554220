import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { SaveSearchGuestModal } from '@/components/srp/save-search/SaveSearchGuestModal'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { GA_SAVE_SEARCH_TYPES } from '@/lib/ga/constants/ga'
import { Flex } from '@/ui/atoms/flex'
import { Toggle } from '@/ui/atoms/toggle'

const SaveSearchFloatingButton = dynamic(
  () => import('./SaveSearchFloatingButton').then((mod) => mod.SaveSearchFloatingButton),
  { ssr: false }
)

const SavedSearchActionFeedback = dynamic(
  () => import('./SavedSearchActionFeedback').then((mod) => mod.SavedSearchActionFeedback),
  { ssr: false }
)

export const SaveSearchToggle = () => {
  const { data: { searchQuery } = {} } = useGetSearchResultsData()

  const keywords = searchQuery?.keywords || ''
  const searchString = searchQuery?.searchString || ''

  const {
    activateError,
    activateLoading,
    deactivateError,
    deactivateLoading,
    handleActivateSaveSearch,
    handleCloseModal,
    handleDeactivateSearch,
    isModalOpen,
    isSearchSaved,
    lookupLoading,
    searchLookup,
    showUserFeedback,
    signInRedirectUrl,
  } = useSaveSearchActions({ searchString })

  const { spacing } = useTheme()
  const { t } = useTranslation(['srp', 'routes'])

  const handleToggleClick = () => {
    // Prevent multiple calls while mutation is still loading
    if (activateLoading || deactivateLoading) return

    if (isSearchSaved && searchLookup) {
      handleDeactivateSearch(searchLookup.id)
      return
    }

    handleActivateSaveSearch(GA_SAVE_SEARCH_TYPES.toggle)
  }

  return (
    <>
      <Flex alignItems="center" gap={spacing.default}>
        <Toggle
          label={t('save_search.toggle.label')}
          labelPosition="left"
          isActive={isSearchSaved}
          onClick={handleToggleClick}
          data-testid="save-search-toggle"
        />
      </Flex>

      <SaveSearchGuestModal
        error={!!activateError || !!deactivateError}
        handleCloseModal={handleCloseModal}
        isModalOpen={isModalOpen}
        keywords={keywords}
        signInRedirectUrl={signInRedirectUrl}
      />

      {
        <>
          {!lookupLoading && !isSearchSaved && (
            <ShowAtOrLarger breakpoint="large">
              <SaveSearchFloatingButton handleActivateSaveSearch={handleActivateSaveSearch} />
            </ShowAtOrLarger>
          )}

          {showUserFeedback && <SavedSearchActionFeedback type={showUserFeedback} />}
        </>
      }
    </>
  )
}
