import dynamic from 'next/dynamic'

import { SrpResults } from '@/components/srp/results-sort/results'
import { SrpResultsSortContainer, SrpSortContainer } from '@/components/srp/results-sort/styled'

const SrpSort = dynamic(() => import('./sort').then((mod) => mod.SrpSort), { ssr: false })

export const SrpResultsSort = () => {
  return (
    <SrpResultsSortContainer>
      <SrpResults showOnLargeBreakpoint />

      <SrpSortContainer>
        <SrpSort />
      </SrpSortContainer>
    </SrpResultsSortContainer>
  )
}
