import { useDecision } from '@optimizely/react-sdk'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import {
  FiltersAccordion,
  FiltersAccordionOrigin,
} from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import {
  FiltersSidebarPanel,
  FiltersSidebarTitle,
  SkyscraperAdSlot,
} from '@/components/srp/filters/FiltersSidebar/styled'
import { FiltersDivider } from '@/components/srp/filters/styled'
import { SrpLoading } from '@/components/srp/srp-loading'
import { GPT_COMPONENT_ID } from '@/features/advertisement/constants/adSlots'
import { FEATURE_FLAG } from '@/lib/optimizely'

export type FiltersSidebarProps = {
  isMobileForAds?: boolean
  isLoadingFilters?: boolean
}

export const FiltersSidebar = ({ isMobileForAds, isLoadingFilters }: FiltersSidebarProps) => {
  const { t } = useTranslation('srp')
  const { colors } = useTheme()
  const [srpQuickFilterPillsDecision] = useDecision(FEATURE_FLAG.SRP_QUICK_FILTERS_PILLS)
  const areQuickFilterPillsActive = srpQuickFilterPillsDecision?.enabled

  return (
    <div>
      <FiltersSidebarPanel data-testid="srp-desktop-filter">
        <FiltersSidebarTitle color={colors.grey.primary} as="h2">
          {t('filters.title')}
        </FiltersSidebarTitle>
        <FiltersDivider />

        {isLoadingFilters ? (
          <SrpLoading
            variant={areQuickFilterPillsActive ? 'sidebar-filter-simplified' : 'sidebar-filter'}
          />
        ) : (
          <FiltersAccordion
            isSimplified={areQuickFilterPillsActive}
            origin={FiltersAccordionOrigin.SIDE_PANEL}
          />
        )}
      </FiltersSidebarPanel>

      {!isMobileForAds && (
        <SkyscraperAdSlot
          id={GPT_COMPONENT_ID.SKYSCRAPER}
          data-testid={GPT_COMPONENT_ID.SKYSCRAPER}
        />
      )}
    </div>
  )
}
