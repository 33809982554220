import { type AppliedFilter, type AppliedRangeFilter } from '@kijiji/generated/graphql-types'

import { isAppliedRangeFilter } from '@/types/search'
import { centsToDollar } from '@/utils/price'

export const getAppliedPriceFilters = (filters?: AppliedFilter[]): string => {
  if (!filters) return ''

  const priceFilter: AppliedRangeFilter | undefined = filters.find(
    (filter) => isAppliedRangeFilter(filter) && filter.filterName === 'price'
  )

  let filterString = ''

  if (priceFilter) {
    if (priceFilter.minValue != undefined) {
      const minValue = centsToDollar(priceFilter.minValue)
      filterString += `prcMn:['${minValue}']`
    }

    if (priceFilter.maxValue != undefined) {
      const maxValue = centsToDollar(priceFilter.maxValue)
      if (filterString) filterString += '; '
      filterString += `prcMx:['${maxValue}']`
    }
  }

  return filterString
}
