import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFavouriteOutlineThick: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.916 4.436c-1.204-1.265-3.31-1.34-4.589-.15l-.15.15-.677.595-.677-.67c-1.204-1.264-3.31-1.338-4.588-.074-.076.074-.076.074-.15.149-1.28 1.338-1.28 3.495 0 4.834l5.114 5.206c.075.075.15.149.301.149.075 0 .226 0 .3-.074l5.116-5.207c1.279-1.413 1.279-3.57 0-4.908z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
    </Icon>
  )
}
export default SvgFavouriteOutlineThick
