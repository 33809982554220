import {
  type AppliedToggleFilter,
  type ToggleFilter as ToggleFilterType,
} from '@kijiji/generated/graphql-types'
import { type FC } from 'react'

import { type FilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { Toggle } from '@/ui/atoms/toggle'

export const ToggleFilter: FC<FilterProps<ToggleFilterType, AppliedToggleFilter>> = ({
  filter,
  refetch,
}) => {
  const { name, label, isSelected, trueValue } = filter
  const id = `${name}-${label}-${trueValue}`

  const onClick = () => {
    if (isSelected) {
      refetch({ filterName: name, toggleValue: '' })
    } else {
      refetch({ filterName: name, toggleValue: trueValue })
    }
  }

  return (
    <Toggle
      key={id}
      id={id}
      label={label}
      defaultChecked={isSelected}
      isActive={isSelected}
      onClick={onClick}
    />
  )
}
