export type SearchAccordionConfig = {
  expandedByDefault: {
    general: string[]
    category: { [id: string]: string[] }
  }
}

export const EXPAND_FILTERS: SearchAccordionConfig['expandedByDefault'] = {
  general: ['priceSection', 'categorySection'],
  category: {
    // There's a bit of duplication here because the filterGroups have different names in v2 and v3 of the filters data
    174: [
      'make',
      'model',
      'year',
      'bodytype',
      'makeSection',
      'modelSection',
      'yearSection',
      'bodyTypeSection',
    ],
  },
}
