import { Toggle } from '@kijiji/ui'
import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { SrpHeaderContainer, SrpHeaderSearchTitle } from '@/components/srp/header/styled'
import { SrpResultsSort } from '@/components/srp/results-sort'
import { SaveSearchToggle } from '@/components/srp/save-search'
import { LoadingSaveSearchToggle } from '@/components/srp/srp-loading/LoadingSaveSearchToggle'
import { LoadingSrpHeader } from '@/components/srp/srp-loading/LoadingSrpHeader'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { useMapSRP } from '@/features/map/hooks/useMapSRP'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { type IntlResponse, isIntlResponse } from '@/types/translations'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

const QuickFilters = dynamic(() =>
  import('@/features/filters/components/QuickFilters/QuickFilters').then((mod) => mod.QuickFilters)
)

/**
 * Top of the search page
 */

export type SrpHeaderProps = {
  h1: string | IntlResponse
  currentPage: number
  loading: boolean
  isMobile?: boolean
  shouldRenderMapSRP: boolean
  setShouldRenderMapSRP: React.Dispatch<React.SetStateAction<boolean>>
}

export const SrpHeader = ({
  h1,
  currentPage,
  loading = true,
  isMobile,
  shouldRenderMapSRP,
  setShouldRenderMapSRP,
}: SrpHeaderProps) => {
  const { t } = useTranslation(['srp', 'filters'])
  const { colors, spacing } = useTheme()
  const { data } = useGetSearchResultsData()
  const { searchQuery } = data ?? {}

  const selectedCategoryId = searchQuery?.category?.id ?? ALL_CATEGORIES_ID_NUM

  const isMapSRPEnabled = useMapSRP(selectedCategoryId)

  const handleRenderMapView = useCallback(() => {
    setShouldRenderMapSRP((oldState) => !oldState)
    const url = new URL(window.location.href)

    if (!shouldRenderMapSRP) {
      url.searchParams.set('bbox', '-79.417998,43.636731,-79.336244,43.665733')
      url.searchParams.set('clusterExpansion', '0')
      url.searchParams.set('geoCluster', '1')
    } else {
      url.searchParams.delete('bbox')
      url.searchParams.delete('clusterExpansion')
      url.searchParams.delete('geoCluster')
    }

    window.history.pushState(null, '', url.toString())
  }, [setShouldRenderMapSRP, shouldRenderMapSRP])

  const [srpQuickFilterPillsDecision] = useDecision(
    FEATURE_FLAG.SRP_QUICK_FILTERS_PILLS,
    undefined,
    {
      overrideAttributes: {
        categoryId: selectedCategoryId,
      },
    }
  )
  const areQuickFilterPillsActive = srpQuickFilterPillsDecision?.enabled

  const shouldDisplayPageNumber = currentPage > 1

  const h1Text = isIntlResponse(h1) ? t(h1.intl, { ...h1.params }) : h1

  return (
    <>
      {loading ? (
        <LoadingSrpHeader />
      ) : (
        <SrpHeaderContainer>
          <>
            <SrpHeaderSearchTitle
              aria-live="polite"
              color={colors.grey.primary}
              data-testid="srp-header-title"
              size="medium"
            >
              {h1Text}
              {shouldDisplayPageNumber &&
                t('header.results.title.page_number', { pageNumber: currentPage })}
            </SrpHeaderSearchTitle>
            <Flex gap="20px" justifyContent="flex-end"></Flex>
          </>
        </SrpHeaderContainer>
      )}

      {areQuickFilterPillsActive && <QuickFilters isMobile={isMobile} />}

      <ShowAtOrLarger breakpoint="medium">
        <Divider bottom={spacing.default} top={spacing.default} />
      </ShowAtOrLarger>

      <Spacing mBottom="0" mTop={spacing.default} large={{ mTop: '0', mBottom: spacing.default }}>
        {loading ? (
          <LoadingSaveSearchToggle />
        ) : (
          <Flex justifyContent="space-between">
            <SaveSearchToggle />
            <Flex gap="20px">
              {isMapSRPEnabled && (
                <Toggle
                  isFullWidth={false}
                  isActive={shouldRenderMapSRP}
                  label={`${shouldRenderMapSRP ? 'Hide' : 'Render'} map`}
                  onClick={handleRenderMapView}
                />
              )}
              {shouldRenderMapSRP && <SrpResultsSort />}
            </Flex>
          </Flex>
        )}
      </Spacing>
    </>
  )
}
