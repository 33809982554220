import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { FilterMobileChip } from '@/components/srp/filters/FilterMobileChip'
import { FiltersModal } from '@/components/srp/filters/FiltersModal'
import { SaveSearchGuestModal } from '@/components/srp/save-search'
import { SavedSearchActionFeedback } from '@/components/srp/save-search/SavedSearchActionFeedback'
import { SaveSearchFloatingButton } from '@/components/srp/save-search/SaveSearchFloatingButton'
import {
  MobileLeaderboard,
  SearchButtonsWrapper,
  SearchFloatingContainer,
} from '@/components/srp/search-floating-buttons/styled'
import { GPT_COMPONENT_ID } from '@/features/advertisement/constants/adSlots'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { useScrollOutsideMainTag } from '@/hooks/useScrollOutsideMainTag'
import { Flex } from '@/ui/atoms/flex'

type SearchFloatingButtons = {
  isMobileForAds?: boolean
}

export const SearchFloatingButtons: FC<SearchFloatingButtons> = ({ isMobileForAds }) => {
  const { spacing } = useTheme()
  const { data: { searchQuery } = {} } = useGetSearchResultsData()
  const { isOutsideMain } = useScrollOutsideMainTag()
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false)

  const keywords = searchQuery?.keywords || ''
  const searchString = searchQuery?.searchString || ''
  const appliedFiltersCount = searchQuery?.filters.length || 0

  const {
    activateError,
    deactivateError,
    handleActivateSaveSearch,
    handleCloseModal: handleSaveSearchModalClose,
    isModalOpen: isSaveSearchModalOpen,
    isSearchSaved,
    lookupLoading,
    showUserFeedback,
    signInRedirectUrl,
  } = useSaveSearchActions({ searchString })

  const handleFilterChipClick = () => {
    setIsFiltersModalOpen(true)
  }

  const handleCloseFiltersModal = () => {
    setIsFiltersModalOpen(false)
  }

  return (
    <>
      <SaveSearchGuestModal
        error={!!activateError || !!deactivateError}
        handleCloseModal={handleSaveSearchModalClose}
        isModalOpen={isSaveSearchModalOpen}
        keywords={keywords}
        signInRedirectUrl={signInRedirectUrl}
      />
      <FiltersModal isOpen={isFiltersModalOpen} onClose={handleCloseFiltersModal} />

      <SearchFloatingContainer>
        {
          <SearchButtonsWrapper
            flexDirection="column"
            gap={spacing.defaultSmall}
            isVisible={!isOutsideMain}
            alignItems="center"
            justifyContent="center"
          >
            {/* to prevent CLS, we nned to wait for the lookup so we know whether to display 1 or 2 buttons */}
            {!lookupLoading && (
              <Flex gap={spacing.default}>
                {!isFiltersModalOpen && (
                  <FilterMobileChip
                    onClick={handleFilterChipClick}
                    appliedFiltersCount={appliedFiltersCount}
                  />
                )}

                {!isSearchSaved && (
                  <SaveSearchFloatingButton handleActivateSaveSearch={handleActivateSaveSearch} />
                )}
              </Flex>
            )}
          </SearchButtonsWrapper>
        }

        {isMobileForAds && (
          <MobileLeaderboard
            id={GPT_COMPONENT_ID.MWEB_LEADERBOARD}
            data-testid={GPT_COMPONENT_ID.MWEB_LEADERBOARD}
          />
        )}
      </SearchFloatingContainer>

      {showUserFeedback && <SavedSearchActionFeedback type={showUserFeedback} />}
    </>
  )
}
