import { isAnyCarsVehiclesCategory, isCarsTrucks } from '@kijiji/category'
import {
  type ListingAttributeV2,
  type ListingFlags,
  type ListingFragment,
  type SearchCategory,
  AdSource,
} from '@kijiji/generated/graphql-types'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { type TFunction } from 'next-i18next'

import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { isDealerListing } from '@/features/listings/utils/isDealerListing'
import { type RouteLocale, ROUTE_LOCALE } from '@/hooks/useLocale'

export const FLAGS = {
  CATEGORY_BADGE: 'categorySpecificBadge',
  EXTERNAL_LISTING: 'externalListingOnly',
  HIGHLIGHT: 'highlight',
  PRICE_DROP: 'priceDrop',
  SHOWCASE: 'showcase',
  TOP_AD: 'topAd',
  SHIPPING: 'shippedBySeller',
  VIRTUAL_TOUR: 'virtualTour',
  PROMO_TOP_AD: 'isPromotionTopAd',
  PROMO_PROV_TOP_AD: 'isPromotionProvTopAd',
  FINANCING: 'financingAvailable',
}

export const SRP_ATTRIBUTES = {
  LOGO: 'srpLogoUrl',
}

export const getAttributesDictionary = (
  attributes: Pick<ListingAttributeV2, 'canonicalName' | 'canonicalValues'>[]
) => {
  return attributes.reduce(
    (
      acc: Record<string, string[]>,
      curr: Pick<ListingAttributeV2, 'canonicalName' | 'canonicalValues'>
    ) => {
      return { ...acc, [curr.canonicalName]: curr.canonicalValues || [] }
    },
    {} as Record<string, string[]>
  )
}

/**
 * Defines if listing has a priceDrop
 * */
export const shouldShowPriceDrop = (
  hasPriceDropFlag: boolean,
  originalPriceAmount?: number
): boolean => {
  if (!hasPriceDropFlag) return false
  if (!originalPriceAmount) return false

  return true
}

type AutosAttributes = {
  carfax?: boolean
  cpo?: boolean
  dealerUpdates?: string[]
  isNew?: boolean
  mileage?: number
  transmission?: number
}

/**
 * It is not possible to transform the keys to snake case since they are not defined as uppercase
 *  */
const DEALER_UPDATES_TRANSLATION_MAP: { [x: string]: string } = {
  homedelivery: 'home_delivery',
  onlinefinancing: 'online_financing',
  onlinepurchasing: 'online_purchasing',
  paymentdeferrals: 'payment_deferrals',
  servicedropoff: 'service_drop_off',
  serviceopen: 'service_open',
  showroomopen: 'showroom_open',
  videochat: 'video_chat',
  virtualappraisal: 'virtual_appraisal',
}

/**
 * Defines Autos attributes for listing:
 * - Transmission type : 1-Manual / 2-Automatic
 * - Mileage in km
 * - Dealer updates - It will return key related to the translation
 * */
export const getAutosAttributes = (
  attributesDictionary: Record<string, string[]>
): AutosAttributes => {
  // Transmission attribute
  const transmission = parseInt(attributesDictionary[ATTRIBUTES.CAR_TRANSMISSION]?.[0] || '0')

  // Mileage attribute in km
  const mileage = parseInt(attributesDictionary[ATTRIBUTES.CAR_MILEAGE]?.[0] || '0')

  // Carfax link
  const carfax = !!attributesDictionary[ATTRIBUTES.CARFAX]?.[0]

  // Certified pre-owned
  const cpo = !!attributesDictionary[ATTRIBUTES.CPO]?.[0]

  // Dealer updates
  const dealerUpdates = Object.keys(DEALER_UPDATES_TRANSLATION_MAP).reduce(
    (acc: string[], curr: string) => {
      const values = attributesDictionary[curr] || []

      if (values.length) {
        return [...acc, DEALER_UPDATES_TRANSLATION_MAP[curr]]
      }
      return acc
    },
    []
  )

  // Vehicle type
  const isNew = attributesDictionary[ATTRIBUTES.VEHICLE_TYPE]?.[0] === 'new'

  return { transmission, mileage, carfax, cpo, dealerUpdates, isNew }
}

const transmissionTypesByKey: Record<number, string> = {
  1: 'listing.attributes.car_transmission.manual',
  2: 'listing.attributes.car_transmission.automatic',
  3: 'listing.attributes.car_transmission.other',
}

export const getTransmissionTypeLiteralKey = (transmissionTypeKey: number) => {
  return transmissionTypesByKey[transmissionTypeKey]
}

export const getCarsVehiclesAttributes = (
  attributesDictionary: Record<string, string[]>,
  categoryId: number,
  routeLocale: RouteLocale,
  t: TFunction
) => {
  // Early return if it is not Autos category
  if (!isAnyCarsVehiclesCategory(categoryId)) {
    return {
      carfax: false,
      carsVehiclesAttributes: [],
      cpo: false,
      dealerUpdates: [],
      isNew: false,
    }
  }

  // Array of texts that will be displayed side by side in the listing card
  const carsVehiclesAttributes = []
  let translatedDealerUpdates: string[] = []

  // Get car attributes
  const {
    carfax = false,
    cpo = false,
    dealerUpdates = [],
    isNew = false,
    mileage,
    transmission,
  } = getAutosAttributes(attributesDictionary)

  if (transmission) {
    const transmissionKey = getTransmissionTypeLiteralKey(transmission)
    if (transmissionKey) carsVehiclesAttributes.push(t(transmissionKey))
  }

  if (mileage) {
    const formattedMileage = formatWholeNumber(mileage, routeLocale === ROUTE_LOCALE.en)
    carsVehiclesAttributes.push(`${formattedMileage} km`)
  }

  if (dealerUpdates.length) {
    translatedDealerUpdates = dealerUpdates.map((item) =>
      t(`listing.attributes.dealer_updates.${item}`)
    )
  }

  return { carfax, cpo, carsVehiclesAttributes, dealerUpdates: translatedDealerUpdates, isNew }
}

/**
 * Get the ad source for a promotion listing based on the flags.
 * @param {ListingFlag[]} flags - The flags associated with the listing.
 * @param {string} [adSource] - The ad source value. Optional.
 * @param {boolean} [isSeeAllTopAd] - Indicates if it is a "See All Top Ad" scenario. Optional.
 * @returns The ad source for the promotion listing, or an empty string if no ad source is found.
 *
 * @example const flags = [
 *     { name: 'PROMO_TOP_AD', value: true },
 *     { name: 'SOME_FLAG', value: false },
 *   ];
 *   const adSource = 'SomeAdSource';
 *   const isSeeAllTopAd = false;
 *   const adSourceForPromotion = getAdSourceForPromotionListing(flags, adSource, isSeeAllTopAd);
 *   console.log(adSourceForPromotion); // Output: 'TOP_AD'
 */
export const getAdSourceForPromotionListing = (
  flags: ListingFlags,
  adSource?: string,
  isSeeAllTopAd?: boolean
) => {
  if (adSource && adSource === AdSource.Organic && !isSeeAllTopAd) {
    return ''
  }

  if (flags.isPromotionTopAd || flags.topAd) {
    return AdSource.TopAd
  } else if (flags.isPromotionProvTopAd) {
    return AdSource.ProvTopAd
  } else {
    return ''
  }
}

/*
  Current Cross Promotion Logic for Kijiji Autos (also known x-promo):
  - Only applicable to MOVE listings
  - Only applicable to dealer listings
  - Only applicable if the current category is Cars & Trucks

  The function returns many listings we decide with the prop `numberOfListingsPerPage`.
  If numberOfListingsPerPage is 1, it will return the first listing that matches the criteria.
  If numberOfListingsPerPage is greater than 1, it will return many listings that matches the criteria.
*/

const isListingApplicableToCrossPromo = (listing: ListingFragment) =>
  isDealerListing(listing.attributes) && listing.adSource === AdSource.Move

export const getCrossPromoConfig = (
  listings: ListingFragment[],
  currentCategory: SearchCategory,
  numberOfListingsPerPage: number
): string[] => {
  if (!isCarsTrucks(currentCategory.id)) return []

  if (numberOfListingsPerPage > 1) {
    const moveDealersListingsIds: string[] = []

    for (let i = 0; i < listings.length; i++) {
      if (moveDealersListingsIds.length >= numberOfListingsPerPage) break
      if (isListingApplicableToCrossPromo(listings[i])) {
        moveDealersListingsIds.push(listings[i].id)
      }
    }

    return moveDealersListingsIds
  }

  const moveDealerListing = listings.find((listing) => isListingApplicableToCrossPromo(listing))
  if (!moveDealerListing) return []

  return [moveDealerListing.id]
}
